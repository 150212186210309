<template>
	<div class="not-found flex-center flex-column">
		<h2 class="white--text w-100 text-center">
			404 Page not Found
		</h2>

		<router-link to="/" class="mt-5">
			Home
		</router-link>
	</div>
</template>

<script>
export default {
	name: "NotFound"
}
</script>

<style scoped lang="scss">
.not-found {
	height: 100vh;
}
</style>